import type React from 'react';
import type {Size} from '../../utils/styles';
import {buttonSizeClass, clsx} from '../../utils/styles';

export interface RadioProps extends Omit<React.HTMLProps<HTMLFieldSetElement>, 'size'> {
  label?: string;
  name: string;
  defaultValue: string;
  items: { value: string; label: string, props?: React.HTMLProps<HTMLInputElement> }[];
  size?: Size;
}

export const Radio: React.FC<RadioProps> = (props) => {
  const {label, name, items, defaultValue, className, size, ...rest} = props;

  return (
      <fieldset className={clsx('flex items-center justify-between', className)} {...rest}>
        {label && <label htmlFor={name} className={'label font-medium'}>
          {label}
        </label>}
        <div className="join">
          {items.map((i) => {
            const {className, ...props} = i.props || {};
            return (<input
                key={i.value}
                type="radio"
                name={name}
                value={i.value}
                aria-label={i.label}
                defaultChecked={defaultValue === i.value}
                className={clsx('join-item btn', buttonSizeClass(size), className)}
                {...props}
            />)
          })}
        </div>
      </fieldset>
  );
};
